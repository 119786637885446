import React, { FC } from 'react'
import styled from 'styled-components/macro'
import CustomLabel from '../../CustomLabel'
import { TControls } from '../../../../features/userInfoForm/types'
import CustomInput from '../../CustomInput'
import { maxLengthRule, minLengthRule, requiredRule } from '../../../utils/formRules'

const PostContainer = styled.div`
  position: relative;
`

type TProps = {
  label: string,
  name: string,
} & TControls

const PostCodeBase: FC<TProps> = ({
  control,
  label,
  name
}) => (
  <PostContainer>
    <CustomLabel label={label}/>
    <CustomInput
      autoComplete="off"
      name={name}
      control={control}
      rules={{
        required: requiredRule(),
        minLength: minLengthRule(2),
        maxLength: maxLengthRule(100)
      }}
    />
  </PostContainer>
)

export default PostCodeBase
