import { useMutation, useQuery, UseQueryResult } from 'react-query'
import axios from 'axios'
import { useSearchParams } from 'react-router-dom'
import { apiClient } from '../../core/api/apiClient'
import {
  TAuthOrganization,
  TBorrowersResponse,
  TLoaner,
  TLoanerResponse,
  TStatus,
  TLoanerReconnect
} from './types'
import { useMultiStepStateContext } from './state/useToken'
import { useStepperContext } from '../../core/components/stepper/StepperContext'
import { useGoogleAnalyticsMetrics } from '../googleAnalytics/hooks/googleAnalyticsHoooks'
import { useReconnectingContext } from '../reconnecting/state/useReconnectingState'
import { borrowerError } from './utils/borrowerError'

export function useAuthOrganizationQuery() {
  return useQuery<TAuthOrganization[], Error>(
    'auth/organization?unpaged=true',
    async (): Promise<TAuthOrganization[]> => {
      try {
        const response = await apiClient.get('auth/organizations?unpaged=true')
        return response.data
      } catch (e) {
        throw new Error('Something went wrong')
      }
    },
    {
      retry: 3
    }
  )
}

export function useLoanerMutation(noNextStep?: boolean) {
  const { setState } = useMultiStepStateContext()
  const { nextStep } = useStepperContext()
  return useMutation<TLoanerResponse, Error, { loaner: TLoaner }>(async ({ loaner }) => {
    try {
      const { data } = await apiClient.post('/loans', loaner)
      return data
    } catch (err: any) {
      if (axios.isAxiosError(err) && err.response) {
        throw new Error(err.response.data.message)
      } else {
        throw new Error(`loaner, ${err}`)
      }
    }
  }, {
    onSuccess: (data) => {
      setState({ loanApplicationId: data.id })
      if (!noNextStep) {
        nextStep()
      }
    }
  })
}

export function useLoanScoreMutation() {
  return useMutation<TLoanerResponse, Error, { loanApplicationId: number }>(
    async ({ loanApplicationId }) => {
      try {
        const { data } = await apiClient.post(`/loans/${loanApplicationId}/score`)
        return data
      } catch (err: any) {
        if (axios.isAxiosError(err) && err.response) {
          throw new Error(err.response.data.message)
        } else {
          throw new Error(err)
        }
      }
    }
  )
}

export function useLoanerReconnectMutation() {
  const { setState } = useMultiStepStateContext()
  const { nextStep } = useStepperContext()
  const { mutateAsync: loanScoreMutate } = useLoanScoreMutation()

  const {
    hasReconnectingBankCheck
  } = useReconnectingContext()

  return useMutation<TLoanerResponse, Error, { loaner: TLoanerReconnect }>(async ({ loaner }) => {
    try {
      const { data } = await apiClient.post('/loans/reconnect/create', loaner)
      return data
    } catch (err: any) {
      if (axios.isAxiosError(err) && err.response) {
        throw new Error(err.response.data.message)
      } else {
        throw new Error(`loaner, ${err}`)
      }
    }
  }, {
    onSuccess: async (data) => {
      if (hasReconnectingBankCheck) {
        setState({ loanApplicationId: data.id })
        nextStep()
        return
      }
      await loanScoreMutate({ loanApplicationId: data.id })
    }
  })
}

export function useBorrowerMutation() {
  const { setState } = useMultiStepStateContext()
  const { sendSubmittingApplicationDetailsMetric } = useGoogleAnalyticsMetrics()

  // eslint-disable-next-line consistent-return
  return useMutation<TBorrowersResponse, Error, any>(async (borrower) => {
    try {
      const { data } = await apiClient.post('/borrowers', borrower)
      return data
    } catch (err: any) {
      borrowerError(err)
    }
  }, {
    onSuccess: (data) => {
      setState({
        borrowerId: data.id
      })

      sendSubmittingApplicationDetailsMetric()
    }
  })
}

export function useLivingStatusQuery(): UseQueryResult<TStatus[]> {
  return useQuery('LivingStatus', async () => {
    try {
      const { data } = await apiClient('/dictionaries/living-status')
      return data
    } catch (err: any) {
      if (axios.isAxiosError(err) && err.response) {
        throw new Error(err.response.data.message)
      } else {
        throw new Error('Something went wrong')
      }
    }
  })
}

export function useEmploymentStatusQuery(): UseQueryResult<TStatus[]> {
  return useQuery('EmploymentStatus', async () => {
    try {
      const { data } = await apiClient('/dictionaries/employment-status')
      return data
    } catch (err: any) {
      if (axios.isAxiosError(err) && err.response) {
        throw new Error(err.response.data.message)
      } else {
        throw new Error('Something went wrong')
      }
    }
  })
}

export function useCymruParnerBorrowerMutation() {
  const { setState } = useMultiStepStateContext()
  const [searchParams] = useSearchParams()
  // eslint-disable-next-line consistent-return
  return useMutation<TBorrowersResponse, Error, any>(async (borrower) => {
    try {
      const { data } = await apiClient.put(`/borrowers/${searchParams.get('borrowerId')}`, borrower)
      return data
    } catch (err: any) {
      borrowerError(err)
    }
  }, {
    onSuccess: (data) => {
      setState({
        borrowerId: data.id
      })
    }
  })
}
