import React, { FC } from 'react'
import { TControls } from '../../../../features/userInfoForm/types'
import PostCodeBase from './PostCodeBase'

const label = 'Post code *'
const name = 'postCode'

const PostCode: FC<TControls> = ({ control }) =>
  <PostCodeBase label={label} name={name} control={control}/>

export default PostCode
