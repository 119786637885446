import { UseFormReturn } from 'react-hook-form'
import React, { FC } from 'react'
import FirstName from '../../../../core/components/controls/FirstName'
import LastName from '../../../../core/components/controls/LastName'
import Email from '../../../../core/components/controls/Email'
import DatePicker from '../../../../core/components/controls/DatePicker'
import EmploymentLength from '../../../../core/components/controls/EmploymentLength'
import IncomeAnnual from '../../../../core/components/controls/IncomeAnnual'
import LivingStatusSelect from '../../../../core/components/controls/LivingStatusSelect'
import EmploymentStatusSelect from '../../../../core/components/controls/EmploymentStatusSelect'
import { EmptyRowStyled, SelectContainerStyled, StatusWrapperStyled, WrapperStyled } from '../../styled'
import CheckBox from '../../../../core/components/controls/CheckBox'
import { TUser } from '../../types'
import PrimaryPhoneNumber from '../../../../core/components/controls/PrimaryPhoneNumber'
import SecondaryPhoneNumber from '../../../../core/components/controls/SecondaryPhoneNumber'
import Employer from '../../../../core/components/controls/Employer'
import { useStatusEmployer } from '../../hooks/useStatusEmployer'
import PostCode from '../../../../core/components/controls/PostCode/PostCode'

type TProps = {
  formMethods: UseFormReturn<Partial<TUser>>
}

const LendologyForm: FC<TProps> = ({ formMethods: { control, watch } }) => {
  const employerShowFlag = useStatusEmployer()

  return (
    <>
      <WrapperStyled>
        <FirstName control={control}/>
        <LastName control={control}/>
        <Email control={control}/>
        <DatePicker control={control}/>
        <EmploymentLength control={control}/>
        <PostCode control={control}/>
        <PrimaryPhoneNumber control={control}/>
        <SecondaryPhoneNumber control={control}/>
        <IncomeAnnual control={control}/>
        {employerShowFlag.includes(watch('employmentStatusId') as number)
          ? (
            <Employer control={control}/>
          )
          : <EmptyRowStyled/>}
      </WrapperStyled>
      <StatusWrapperStyled>
        <SelectContainerStyled>
          <LivingStatusSelect control={control}/>
          <EmploymentStatusSelect control={control}/>
        </SelectContainerStyled>
      </StatusWrapperStyled>
      <CheckBox control={control}/>
    </>
  )
}
export default LendologyForm
