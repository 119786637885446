import { UseFormReturn } from 'react-hook-form'
import React, { FC, useState } from 'react'
import BuildingNumber from '../../../../core/components/controls/BuildingNumber'
import BuildingName from '../../../../core/components/controls/BuildingName'
import Town from '../../../../core/components/controls/Town'
import Country from '../../../../core/components/controls/Country'
import CustomLabel from '../../../../core/components/CustomLabel'
import CustomRadioButton from '../../../../core/components/CustomRadioButton'
import { RadioIcon } from '../../../../core/components/icons/RadioIcon'
import PrevBuildingNumber from '../../../../core/components/controls/PrevBuildingNumber'
import PrevBuildingName from '../../../../core/components/controls/PrevBuildingName'
import PrevStreet from '../../../../core/components/controls/PrevStreet'
import PrevTown from '../../../../core/components/controls/PrevTown'
import PrevCountry from '../../../../core/components/controls/PrevCountry'
import { RadioWrapper, RadioContentStyled, RadioButtonStyled, PeriodStyled } from '../../styled'
import { TFormData } from '../../types'
import Street from '../../../../core/components/controls/Street'
import PostCode from '../../../../core/components/controls/PostCode/PostCode'
import PrevPostCode from '../../../../core/components/controls/PostCode/PrevPostCode'

type TProps = {
  formMethods: UseFormReturn<TFormData>
}
const PlendResubmitForm: FC<TProps> = ({ formMethods: { control } }) => {
  const [isSelectedValue, setIsSelectedValue] = useState(true)
  const handleChange = () => {
    setIsSelectedValue(!isSelectedValue)
  }
  return (
    <>
      <PostCode control={control}/>
      <BuildingNumber control={control}/>
      <BuildingName control={control}/>
      <Street control={control}/>
      <Town control={control}/>
      <Country control={control}/>
      <RadioWrapper>
        <CustomLabel label="Length of time at Address *"/>
        <RadioButtonStyled>
          <RadioContentStyled>
            <CustomRadioButton
              data-testid="period-short"
              checked={!isSelectedValue}
              onChange={handleChange}
              width={32}
              height={32}
              checkedIcon={<RadioIcon width={18} height={18}/>}
            />
            <PeriodStyled>0-1 years</PeriodStyled>
          </RadioContentStyled>
          <RadioContentStyled>
            <CustomRadioButton
              data-testid="period-long"
              checked={isSelectedValue}
              onChange={handleChange}
              width={32}
              height={32}
              checkedIcon={<RadioIcon width={18} height={18}/>}
            />
            <PeriodStyled>1+ years</PeriodStyled>
          </RadioContentStyled>
        </RadioButtonStyled>
      </RadioWrapper>
      {!isSelectedValue
        && (
          <>
            <PrevPostCode control={control}/>
            <PrevBuildingNumber control={control}/>
            <PrevBuildingName control={control}/>
            <PrevStreet control={control}/>
            <PrevTown control={control}/>
            <PrevCountry control={control}/>
          </>
        )}
    </>
  )
}

export default PlendResubmitForm
