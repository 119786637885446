import React, { FC, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import styled from 'styled-components/macro'
import { useReconnectingContext } from '../../../reconnecting/state/useReconnectingState'
import {
  commonGap,
  ContentStyled,
  EmptyRowStyled,
  PeriodStyled,
  RadioButtonContainer,
  RadioButtonStyled,
  RadioWrapperStyled,
  SecondAddressStyled,
  SelectContainerStyled,
  StatusWrapperStyled,
  UpdatesSubscription,
  WrapperStyled
} from '../../styled'
import FirstName from '../../../../core/components/controls/FirstName'
import LastName from '../../../../core/components/controls/LastName'
import Email from '../../../../core/components/controls/Email'
import DatePicker from '../../../../core/components/controls/DatePicker'
import EmploymentLength from '../../../../core/components/controls/EmploymentLength'
import IncomeAnnual from '../../../../core/components/controls/IncomeAnnual'
import BuildingNumber from '../../../../core/components/controls/BuildingNumber'
import BuildingName from '../../../../core/components/controls/BuildingName'
import Street from '../../../../core/components/controls/Street'
import Town from '../../../../core/components/controls/Town'
import Country from '../../../../core/components/controls/Country'
import CustomLabel, { LabelStyled as DebtConsolidationLabel } from '../../../../core/components/CustomLabel'
import CustomRadioButton from '../../../../core/components/CustomRadioButton'
import { RadioIcon } from '../../../../core/components/icons/RadioIcon'
import PrimaryPhoneNumber from '../../../../core/components/controls/PrimaryPhoneNumber'
import SecondaryPhoneNumber from '../../../../core/components/controls/SecondaryPhoneNumber'
import PrevBuildingNumber from '../../../../core/components/controls/PrevBuildingNumber'
import PrevBuildingName from '../../../../core/components/controls/PrevBuildingName'
import PrevStreet from '../../../../core/components/controls/PrevStreet'
import PrevTown from '../../../../core/components/controls/PrevTown'
import PrevCountry from '../../../../core/components/controls/PrevCountry'
import LivingStatusSelect from '../../../../core/components/controls/LivingStatusSelect'
import EmploymentStatusSelect from '../../../../core/components/controls/EmploymentStatusSelect'
import Employer from '../../../../core/components/controls/Employer'
import CheckBox from '../../../../core/components/controls/CheckBox'
import { fontFamily } from '../../../../core/styles/mixins'
import CustomSelect, { ContainerStyled } from '../../../../core/components/CustomSelect'
import CustomInput from '../../../../core/components/CustomInput'
import { maxLengthRule, requiredRule } from '../../../../core/utils/formRules'
import DebtConsolidation, {
  RemoveIconWrapStyled,
  WrapStyled
} from '../../../socialCreditLoanPurpose/components/DebtConsolidation'
import {
  useFieldsReset,
  useFieldsShowing,
  useOptions
} from '../../../socialCreditLoanPurpose/components/SocialCreditLoanPurpose'
import { useStatusEmployer } from '../../hooks/useStatusEmployer'
import PostCode from '../../../../core/components/controls/PostCode/PostCode'
import PrevPostCode from '../../../../core/components/controls/PostCode/PrevPostCode'

const CheckboxWrap = styled.div`
  //grid-column: 1/3;
`

const FormWrapperStyled = styled.div`
  ${UpdatesSubscription} {
    max-width: none;
  }

  ${StatusWrapperStyled} {
    @media (max-width: 768px) {
      max-width: none;
    }
  }

  ${ContainerStyled} {
    ${fontFamily('Open Sans')};
  }

`

const LabelStyled = styled.p`
  ${fontFamily('Open Sans')};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
`

const ColumnStyled = styled.div<{ gridTempColumn?: string }>`
  display: grid;
  grid-template-columns:repeat(2, minmax(300px, 484px));
  gap: ${commonGap};
  margin-top: ${commonGap};
  align-items: start;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(auto, auto));
  }
`

const DebtConsolidationWrapper = styled.div`
  &&& {
    ${WrapStyled} {
      grid-template-columns: repeat(2, 1fr);
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, minmax(auto, auto));
      }

      ${DebtConsolidationLabel} {
        ${fontFamily('Open Sans')};
        color: ${({ theme }) => theme.colors.white};
        margin-top: 0;
      }

      ${RemoveIconWrapStyled} {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`

const ShowOtherStyled = styled.div`
  display: flex;
  flex-direction: column;
`

type TProps = {
  formMethods: UseFormReturn<any>
}
const ConnectSocialCreditForm: FC<TProps> = ({ formMethods }) => {
  const { control, watch, getValues } = formMethods
  const [isSelectedValue, setIsSelectedValue] = useState(!getValues().prevPostCode)
  const employerShowFlag = useStatusEmployer()

  const { reasonLevelOneOptions, reasonLevelTwoOptions } = useOptions(formMethods as any)
  const {
    showOtherText,
    showAdditionalReason,
    showDebtConsolidation
  } = useFieldsShowing((formMethods as any), reasonLevelTwoOptions)
  useFieldsReset(formMethods as any)
  const { isReconnectingProcess } = useReconnectingContext()
  const reasonLevelOneWatch = watch('reasonLevelOne')
  const handleChange = () => {
    setIsSelectedValue(!isSelectedValue)
  }

  return (
    <FormWrapperStyled>
      <LabelStyled>Application</LabelStyled>
      <WrapperStyled maxWidth={998}>
        <FirstName disabled={isReconnectingProcess} control={control}/>
        <LastName disabled={isReconnectingProcess} control={control}/>
        <Email disabled={isReconnectingProcess} control={control}/>
        <DatePicker disabled={isReconnectingProcess} control={control}/>
        <EmploymentLength control={control}/>
        <IncomeAnnual control={control}/>
        <PostCode control={control}/>
        <BuildingNumber control={control}/>
        <BuildingName control={control}/>
        <Street control={control}/>
        <Town control={control}/>
        <Country control={control}/>
      </WrapperStyled>
      <RadioButtonContainer>
        <CustomLabel label="Length of time at Address *"/>
        <RadioButtonStyled>
          <RadioWrapperStyled>
            <CustomRadioButton
              data-testid="period-short"
              checked={!isSelectedValue}
              onChange={handleChange}
              width={32}
              height={32}
              checkedIcon={<RadioIcon width={18} height={18}/>}
            />
            <PeriodStyled>0-1 years</PeriodStyled>
          </RadioWrapperStyled>
          <RadioWrapperStyled>
            <CustomRadioButton
              data-testid="period-long"
              checked={isSelectedValue}
              onChange={handleChange}
              width={32}
              height={32}
              checkedIcon={<RadioIcon width={18} height={18}/>}
            />
            <PeriodStyled>1+ years</PeriodStyled>
          </RadioWrapperStyled>
        </RadioButtonStyled>
      </RadioButtonContainer>
      <ContentStyled marginBottom={30}>
        <PrimaryPhoneNumber control={control}/>
        <SecondaryPhoneNumber control={control}/>
      </ContentStyled>
      {!isSelectedValue
        && (
          <SecondAddressStyled>
            <PrevPostCode control={control}/>
            <PrevBuildingNumber control={control}/>
            <PrevBuildingName control={control}/>
            <PrevStreet control={control}/>
            <PrevTown control={control}/>
            <PrevCountry control={control}/>
          </SecondAddressStyled>
        )}
      <StatusWrapperStyled>
        <SelectContainerStyled>
          <LivingStatusSelect control={control}/>
          <EmploymentStatusSelect control={control}/>
        </SelectContainerStyled>
      </StatusWrapperStyled>
      <ColumnStyled>
        {employerShowFlag.includes(watch('employmentStatusId') as number)
          ? (
            <Employer control={control}/>
          )
          : <EmptyRowStyled/>}
        <CustomSelect
          name="reasonLevelOne"
          placeholder="Please select the reason"
          control={control}
          label={<CustomLabel label="Loan Purpose *"/>}
          options={reasonLevelOneOptions}
        />
      </ColumnStyled>
      <ColumnStyled>
        {showAdditionalReason && (
          <CustomSelect
            name="reasonLevelTwo"
            placeholder="Please select the reason"
            control={control}
            options={reasonLevelTwoOptions}
            label={<CustomLabel label={`${reasonLevelOneWatch} (please specify) *`}/>}
          />
        )}
      </ColumnStyled>
      <ContentStyled>
        {showOtherText && (
          <ShowOtherStyled>
            <CustomLabel label="Other (Please specify) *"/>
            <CustomInput
              multiline
              minRows={7}
              name="otherText"
              control={control}
              rules={{
                required: requiredRule(),
                maxLength: maxLengthRule(255)
              }}
            />
          </ShowOtherStyled>
        )}
        {showDebtConsolidation && (
          <DebtConsolidationWrapper>
            <DebtConsolidation showLabel useFormReturn={formMethods}/>
          </DebtConsolidationWrapper>
        )}
      </ContentStyled>
      <CheckboxWrap>
        <CheckBox
          control={control}
          text="I want to stay up to date and receive offers, news and product updates from Social Credit Plend"/>
      </CheckboxWrap>

    </FormWrapperStyled>
  )
}

export default ConnectSocialCreditForm
